import { render, staticRenderFns } from "./DeptSelectTree.vue?vue&type=template&id=21be18b2&scoped=true"
import script from "./DeptSelectTree.vue?vue&type=script&lang=js"
export * from "./DeptSelectTree.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21be18b2",
  null
  
)

export default component.exports